<template>
  <Splitter style="border: unset">
    <SplitterPanel :size="65"
      ><div id="yasgui" class="yasgui"></div
    ></SplitterPanel>

    <SplitterPanel :size="35">
      <br />
      <Card class="examples">
        <template #title> Introduction and examples </template>
        <template #content>
          <p>
            Here you can see some examples that you can get from
            <a
              href="https://github.com/cp-weiland/FLOPOChapterPhenotypeRCN"
              target="_blank"
              ><br /><i class="pi pi-github"></i>
              https://github.com/cp-weiland/FLOPOChapterPhenotypeRCN</a
            >
          </p>
          <p>
            Click on an example to select the query. After you can modified the
            selected query or just start the request. Also you can use multiple
            Tabs which differet queries.
          </p>

          <p class="selectQuery" @click="showQuery('black_seeds')">
            <i class="pi pi-star-o"></i>
            Click here to select black_seeds
          </p>
          <p class="selectQuery" @click="showQuery('FLOPOknb_UniProt')">
            <i class="pi pi-star-o"></i>

            Click here to select FLOPOknb_UniProt
          </p>
        </template>

        <!-- <template #footer
          ><hr />
          Information technology &amp; Biodiversity informatics
          <br />Senckenberg – Leibniz Institution for Biodiversity and Earth
          System Research
        </template> -->
      </Card>
    </SplitterPanel>
  </Splitter>
  <!-- <div id="yasgui" class="yasgui"></div> -->
</template>

<script>
import YASGUI from "@triply/yasgui";
import "@triply/yasgui/build/yasgui.min.css"; // it's necessary
import queries from "./SparqlQueries";

import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import Card from "primevue/card";

export default {
  name: "Sparql",
  components: { Splitter, SplitterPanel, Card },
  data: function () {
    return {
      yasgui: null,
      queries: queries,
    };
  },

  methods: {
    showQuery: function (queryName) {
      this.yasgui.getTab().setQuery(this.queries[queryName]);
    },
  },
  mounted: function () {
    YASGUI.defaults.requestConfig = {
      endpoint: "https://semantics.senckenberg.de/sparql-endpoint",
      method: "GET",
      headers: {},
    };

    document.getElementById("yasgui").innerHTML = ""; //It's necessary for the build-version
    this.yasgui = new YASGUI(document.getElementById("yasgui"));

    document.getElementsByClassName("controlbar")[0].style.visibility =
      "hidden";
    document.getElementsByClassName("controlbar")[0].style.display = "none";
  },
};
</script>

<style scoped lang="scss">
.selectQuery {
  font-weight: 600;
  cursor: grab;
}
.examples {
  margin-left: 0.5rem;
  border-radius: 2rem;

  background: rgb(184 192 152);
}
.yasgui {
  margin-right: 0.5rem;
}
</style>
