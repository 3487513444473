import { createRouter, createWebHistory } from "vue-router";

import Sparql from "./views/Sparql";

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/about",
      name: "about",
      component: () => import("./views/About"),
    },
    {
      path: "/",
      name: "sparql",
      component: Sparql,
    },
    {
      path: "/namespaces",
      name: "namespaces",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Namespaces"),
    },
    {
      path: "/inferencerulesets",
      name: "inferencerulesets",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/InferenceRuleSets"),
    },
    {
      path: "/rdfviews",
      name: "rdfviews",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/RDFviews"),
    },

    {
      path: "/imprint",
      name: "imprint",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Imprint"),
    },
  ],
});
