<template>
  <Menubar :model="items" style="border: unset"> </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";

export default {
  name: "app-header",
  data() {
    return {
      items: [
        {
          label: "About",
          icon: "pi pi-fw pi-info-circle",
          to: "/about",
        },
        {
          label: "SPARQL",
          icon: "pi pi-fw pi-home",
          to: "/",
        },
        {
          label: "Namespaces",
          icon: "pi pi-fw pi-tags",
          to: "/namespaces",
        },
        {
          label: "Inference Rule Sets",
          icon: "pi pi-fw pi-book",
          to: "/inferencerulesets",
        },
        {
          label: "RDF Views",
          icon: "pi pi-fw pi-eye",
          to: "/rdfviews",
        },
      ],
    };
  },
  components: { Menubar },
};
</script>

<style></style>
