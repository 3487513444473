<template>
  <div class="head">
    <div class="headTitleText">SPARQL Query Editor</div>
    <div class="headTitle"></div>
  </div>
  <Header />
  <div class="viewContent">
    <router-view></router-view>
  </div>
  <br />
  <div style="text-align: center; font-size: 0.8rem">
    <hr />
    <router-link to="imprint">Imprint</router-link><br />
    Copyright © 2021
    <a href="http://www.openlinksw.com/virtuoso" target="_blank"
      >OpenLink Software</a
    >
    <br />Virtuoso version 07.20.3230 on Linux (x86_64-unknown-linux-gnu),
    Single Server Edition
  </div>
</template>

<script>
import Header from "./components/TheHeader.vue";

export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style lang="scss">
$bold: 700;

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

.head {
  background: #909c59;
}
.headTitle {
  height: 2rem;
  background: linear-gradient(to bottom, #909c59, rgb(247, 248, 249));
}
.headTitleText {
  position: relative;
  margin-left: 1.7rem;
  font-family: Verdana, sans-serif;
  font-size: 1.6rem;
  color: white;
  font-weight: 900;
}

a {
  color: #909c59;
  font-weight: $bold;
}

.viewTitle {
  font-weight: $bold;
  font-size: 1.2rem;
}
.viewContent {
  font-weight: 400;
}
.viewContentBold {
  font-weight: $bold;
}
</style>
