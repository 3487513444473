import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router.js"; // sudo npm install -g vue-router@4

// External
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css"; //theme
import "primevue/resources/primevue.min.css"; //core css
import "primeicons/primeicons.css"; //icons

import "primeflex/primeflex.css"; // PrimeFlex (npm install primeflex)

createApp(App).use(PrimeVue).use(Router).mount("#app");
